import { getKeywordScoutKeywordsSelected } from 'helpers/keyword_engine'
import { DEFAULT_KEYWORD_ENGINE_FILTERS } from 'constants/filters'
import { getShowState } from 'helpers/core'
import * as type from 'constants/action_types'

export const initialState = {
  loadKeywords: {
    isLoading: false,
    completed: false
  },

  generateCSV: {
    isLoading: false
  },

  initialLoad: true,
  checkAll: false,
  numberChecked: 0,
  showKeywordScoutPresetSave: false,
  showKeywordScoutPresetLoad: false,
  showKeywordScoutPresetDelete: false,

  data: {
    total_count: 0,
    total_pages: 1,
    selectedPage: 1,
    keywords: [],
    selectedKeywords: [],
    keywordLists: [],
    currencyCode: 'USD',
    selectedCountry: 'us',
    product: null,
    multiAsinProducts: [],
    keyword: null,
    searchTerms: [],
    previousSearch: []
  },

  presets: {
    isLoading: false,
    name: '',
    userPresets: [],
    selectedPreset: {}
  },

  filters: DEFAULT_KEYWORD_ENGINE_FILTERS,
  allVariants: false
}

function keywordEngineReducer(state = initialState, action) {
  switch (action.type) {
    case type.PENDING_KEYWORD_ENGINE_ACTION:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          isLoading: true
        }
      }
    case type.COMPLETED_KEYWORD_ENGINE_ACTION:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          isLoading: false,
          completed: true
        }
      }
    case type.RESET_KEYWORD_ENGINE_ACTION:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          isLoading: false,
          completed: false
        }
      }
    case type.RESET_KEYWORD_ENGINE:
      return {
        ...state,
        ...initialState,
        allVariants: state.allVariants
      }
    case type.COMPLETED_GET_RELATED_KEYWORDS:
      return {
        ...state,
        initialLoad: false,
        data: {
          ...state.data,
          ...action.payload.data
        },
        filters: {
          ...state.filters
        }
      }
    case type.SET_KEYWORD_ENGINE_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.prop]: action.payload.value
        }
      }
    case type.SET_KEYWORD_ENGINE_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.name]: {
            ...state.filters[action.payload.name],
            [action.payload.property]: action.payload.value
          }
        }
      }
    case type.SET_KEYWORD_ENGINE_FILTERS:
      return {
        ...state,
        filters: { ...action.payload.filters }
      }
    case type.RESET_KEYWORD_ENGINE_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload]: initialState.filters[action.payload]
        }
      }
    case type.RESET_KEYWORD_ENGINE_FEATURE_TERM:
      return {
        ...state,
        data: {
          ...state.data,
          product: initialState.data.product,
          keyword: initialState.data.keyword,
          keywords: initialState.data.keywords
        }
      }
    case type.SET_KEYWORD_ENGINE_COUNTRY:
      return {
        ...state,
        data: {
          ...state.data,
          currencyCode: action.payload.currencyCode,
          selectedCountry: action.payload.country
        },
        filters: {
          ...state.filters,
          country: {
            ...state.filters.country,
            valuesArray: [action.payload.country]
          }
        }
      }
    case type.RESET_KEYWORD_SELECTION: {
      return {
        ...state,
        checkAll: false,
        numberChecked: 0,
        data: {
          ...state.data,
          selectedKeywords: []
        }
      }
    }
    case type.TOGGLE_CHECK_ALL_KEYWORD_SCOUTS: {
      const selectedKeywords = state.checkAll
        ? []
        : [...state.data.keywords, state.data.keyword]

      return {
        ...state,
        checkAll: getShowState(state.checkAll),
        numberChecked: selectedKeywords.length,
        data: {
          ...state.data,
          selectedKeywords
        }
      }
    }
    case type.TOGGLE_KEYWORD_SELECTED: {
      const selected = getKeywordScoutKeywordsSelected(
        state.data.selectedKeywords,
        action.keyword
      )
      return {
        ...state,
        checkAll: selected.length === state.data.keywords.length,
        numberChecked: selected.length,
        data: {
          ...state.data,
          selectedKeywords: selected
        }
      }
    }
    case type.TOGGLE_KEYWORD_SCOUT_SAVE_PRESET_MODAL:
      return {
        ...state,
        showKeywordScoutPresetSave: getShowState(
          state.showKeywordScoutPresetSave,
          action.show
        )
      }
    case type.TOGGLE_KEYWORD_SCOUT_LOAD_PRESET_MODAL:
      return {
        ...state,
        showKeywordScoutPresetLoad: getShowState(
          state.showKeywordScoutPresetLoad,
          action.show
        )
      }
    case type.SET_KEYWORD_SCOUT_PRESET_NAME:
      return {
        ...state,
        presets: {
          ...state.presets,
          name: action.value
        }
      }
    case type.PENDING_KEYWORD_SCOUT_SAVE_PRESET:
      return {
        ...state,
        presets: {
          ...state.presets,
          isLoading: true
        }
      }
    case type.COMPLETED_KEYWORD_SCOUT_SAVE_PRESET:
      return {
        ...state,
        presets: {
          ...state.presets,
          isLoading: false
        }
      }
    case type.SET_KEYWORD_SCOUT_PRESETS: {
      return {
        ...state,
        presets: {
          ...state.presets,
          userPresets: action.userPresets
        }
      }
    }
    case type.SET_SELECTED_KEYWORD_SCOUT_PRESET: {
      return {
        ...state,
        presets: {
          ...state.presets,
          selectedPreset: action.value
        }
      }
    }
    case type.PENDING_KEYWORD_SCOUT_LOAD_PRESET: {
      return {
        ...state,
        presets: {
          ...state.presets,
          isLoading: true
        }
      }
    }
    case type.COMPLETED_KEYWORD_SCOUT_LOAD_PRESET: {
      return {
        ...state,
        presets: {
          ...state.presets,
          isLoading: false
        }
      }
    }
    case type.LOAD_KEYWORD_SCOUT_PRESET: {
      return {
        ...state,
        filters: {
          ...action.filters
        }
      }
    }
    case type.TOGGLE_KEYWORD_SCOUT_DELETE_PRESET_MODAL: {
      return {
        ...state,
        showKeywordScoutPresetDelete: getShowState(
          state.showKeywordScoutPresetDelete,
          action.show
        ),
        showKeywordScoutPresetLoad: getShowState(
          state.showKeywordScoutPresetLoad,
          action.show
        )
      }
    }
    case type.PENDING_KEYWORD_SCOUT_DELETE_PRESET: {
      return {
        ...state,
        presets: {
          ...state.presets,
          isLoading: true
        }
      }
    }
    case type.COMPLETED_KEYWORD_SCOUT_DELETE_PRESET: {
      return {
        ...state,
        presets: {
          ...state.presets,
          isLoading: false
        }
      }
    }
    case type.SET_INCLUDE_ALL_VARIANTS_TOGGLE:
      return {
        ...state,
        allVariants: action.payload
      }
    default:
      return state
  }
}

export default keywordEngineReducer
